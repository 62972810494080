import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

import { SectionTitle, ImageSharpFluid } from 'helpers/definitions';

import * as Styled from './styles';

interface Testimonial {
  node: {
    id: string;
    frontmatter: {
      review: string;
    };
  };
}

const Testimonials: React.FC = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "testimonials section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "testimonials" } } }) {
        edges {
          node {
            id
            frontmatter {
              review
            }
          }
        }
      }
    }
  `);

  const sectionTitle: SectionTitle = markdownRemark.frontmatter;
  const testimonials: Testimonial[] = allMarkdownRemark.edges;

  return (
    <Styled.Banner>
      <Container>
        <TitleSection title={sectionTitle.title} center />
        <Styled.Testimonials>
            {testimonials.map((item) => {
              const {
                id,
                frontmatter: { review }
              } = item.node;

              return (
                <Styled.Testimonial key={id}>
                  <Styled.ReviewText>{review}</Styled.ReviewText>
                  <Styled.Stars>★★★★★</Styled.Stars>
                </Styled.Testimonial>
              );
            })}
        </Styled.Testimonials>
      </Container>
    </Styled.Banner>

  );
};

export default Testimonials;
