import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Testimonials = styled.div`
  ${tw`mx-auto md:flex items-center justify-center w-full`};
`;

export const Banner = styled.section`
  ${tw`bg-gray-100 border-b border-indigo-100`};
`;

export const Testimonial = styled.div`
  ${tw`flex flex-col items-center text-center md:w-1/3`};
`;

export const Image = styled.figure`
  ${tw`w-16 h-16 mx-auto border border-teal-400 rounded-full`};

  img {
    ${tw`border-4 border-white rounded-full`};
  }
`;

export const ReviewText = styled.h3`
  ${tw`italic mb-0`};
`;

export const Stars = styled.p`
  ${tw`font-semibold mb-4 text-yellow-400`};
`;
